
import { Component, Mixins, Prop, Emit, Watch } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { HasProperyPart } from "@/mixins/has-property-part";

@Component({
    components: {
        InputRadio: () => import("@/components/forms/property-radio.vue"),
        ValidationProvider,
    },
})
export default class PropertyPartEquimentKitchen extends Mixins(HasProperyPart) {
    @Prop({ required: true }) value?: PropertyEquimentKitchen;

    localValue: PropertyEquimentKitchen = {
        dishwasher: null,
        air_extractor: null,
        oven: null,
        combi_oven: null,
        microwave_oven: null,
        steam_oven: null,
        sink_type: null,
        coffee_machine: null,
        stove: null,
        stove_type: null,
        fridge: null,
    };

    @Emit("input")
    handleInput() {
        return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: PropertyEquimentKitchen) {
        this.localValue = newValue;
    }
}
